<template>
  <div>
    <component :is="component" />
  </div>
</template>

<script>
import Security from '@/views/settings/agents/agent/notifications/security.vue';
import Productivity from '@/views/settings/agents/agent/notifications/productivity.vue';

export default {
  data: () => ({}),
  computed: {
    component() {
      switch (this.$route.params.log) {
        case 'securityNotifications':
          return Security;
        case 'productivityNotifications':
          return Productivity;
        default:
          throw Error(`Unknown default log ${this.$route.params.log}`);
      }
    },
  },
};
</script>
