<template>
  <div>
    <div v-for="sett in settings" :key="sett.stype">
      <div :class="[$style.card]">
        <div
          :class="[$style.settContainer, $style.toggle]"
          @click="check(sett.stype)"
        >
          <div :class="[$style.label, $style.idle]">
            {{ sett.description }}
          </div>

          <div
            v-if="sett.stype === 22 || sett.stype === 24"
            :class="$style.flex"
          >
            <input
              v-model.number="sett.val"
              type="number"
              :class="$style.input"
              :disabled="!sett.checked"
              @change="change(sett.stype)"
              @click.stop
            >
            <div :class="$style.measure">
              минут
            </div>
          </div>
          <toggle-button
            v-model="sett.checked"
            :value="sett.checked"
            :sync="true"
            @click.native.prevent
          />
        </div>
        <div
          v-if="sett.stype === 23 || sett.stype === 25"
          :class="[$style.settContainer, $style.additional]"
          @click="check(sett.stype, true)"
        >
          <div :class="$style.label">
            Учитывать только рабочее время
          </div>
          <toggle-button
            v-model="sett.worktime"
            :value="sett.worktime"
            :sync="true"
            :disabled="!sett.checked"
            @click.native.prevent
          />
        </div>
        <div v-else :class="$style.switcher">
          <button
            :disabled="!sett.checked"
            :class="[$style.button, { [$style.activeButton]: sett.worktime }]"
            @click="check(sett.stype, true)"
          >
            {{ sett.options[0] }}
          </button>
          <button
            :disabled="!sett.checked"
            :class="[$style.button, { [$style.activeButton]: !sett.worktime }]"
            @click="check(sett.stype, true)"
          >
            {{ sett.options[1] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import { getAcselParams } from '@/api/methods/settings';

Vue.component('ToggleButton', ToggleButton);

export default {
  data: () => ({
    settings: [
      {
        description: 'Простой (бездействие) пользователя в рабочее время более',
        stype: 22,
        checked: false,
        worktime: false,
        val: 10,
        sval: '',
        options: ['Суммарно за рабочее время', 'Подряд'],
      },
      {
        description: 'Посещение непродуктивных сайтов',
        stype: 23,
        checked: false,
        worktime: false,
        sval: '',
        val: 0,
      },
      {
        description: 'Проведено на непродуктивных сайтах более',
        stype: 24,
        checked: false,
        worktime: false,
        val: 15,
        sval: '',
        options: ['Учитывать только рабочее время', 'За день'],
      },
      {
        description: 'Запуск непродуктивных программ',
        stype: 25,
        checked: false,
        worktime: false,
        sval: '',
        val: 0,
      },
    ],
    result: {},
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    check(stype, additional) {
      const indexOfObject = this.settings.findIndex(
        (object) => object.stype === stype,
      );
      if (additional) {
        if (this.settings[indexOfObject].checked) {
          this.$set(
            this.settings[indexOfObject],
            'worktime',
            !this.settings[indexOfObject].worktime,
          );
        }
        if (this.settings[indexOfObject].worktime) {
          if (stype === 22) this.settings[indexOfObject].sval = 'SWT';
          else this.settings[indexOfObject].sval = 'WTO';
        } else this.settings[indexOfObject].sval = '';
      } else {
        this.$set(
          this.settings[indexOfObject],
          'checked',
          !this.settings[indexOfObject].checked,
        );
      }

      this.$store.commit('settings/addAcsel', this.settings[indexOfObject]);
    },
    change(stype) {
      const indexOfObject = this.settings.findIndex(
        (object) => object.stype === stype,
      );
      this.$store.commit('settings/addAcsel', this.settings[indexOfObject]);
    },
    async getSettings() {
      this.result = {};
      if (this.selectedComputer.id) {
        this.promise = await getAcselParams(
          this.selectedComputer.id,
          this.selectedComputer.type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
            const settings = this.settings.slice();
            settings.forEach((item) => {
              const found = this.result.find(
                (sett) => sett.stype === item.stype,
              );
              if (found) {
                item.checked = true;
                item.val = found.val;
                item.sval = found.sval;
                if (found.sval === 'WTO' || found.sval === 'SWT') {
                  item.worktime = true;
                }
              }
            });
            this.settings = settings;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 60px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 230px;
  cursor: pointer;
}

.title {
  margin: 20px 20px 10px 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.measure {
  font-size: 14px;
}

.idle {
  width: 300px;
  margin: 10px;
  line-height: 20px;
}
.flex {
  display: flex;
  align-items: baseline;
}

.additional {
  padding: 10px 0px 10px 50px;
  margin: 10px;
}

.button {
  height: 40px;
  cursor: pointer;
  width: 210px;
  background: #bfcbd9;
  border: 0px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  &:hover {
    background: $dark-gray;
    transition: background-color 0.5s linear;
  }
  &:disabled {
    background: $branchColor;
  }
}
.activeButton {
  background: #69b382;
  &:hover {
    background: #5d9f74;
    transition: background-color 0.5s linear;
  }
  &:disabled {
    background: $branchColor;
  }
}

.switcher {
  margin: 20px;
  display: flex;
}
</style>
