import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

function getSettings(link) {
  return axios.post(link).then((response) => {
    if (response.data.success) { return response; }

    openToast(response.data.message, 'error');
    response.data = null;

    return response;
  });
}

export function getAllSettings(id, type) {
  return getSettings(`/settings/?id=${id}&type=${type}`);
}

export function getLogSettings(id, type) {
  return getSettings(`/settings/log/?id=${id}&type=${type}`);
}

export function getCommonSettings(id, type) {
  return getSettings(`/settings/common/?id=${id}&type=${type}`);
}

export function getScreenshotSettings(id, type) {
  return getSettings(`/settings/screenshot/?id=${id}&type=${type}`);
}
export function getScreenshotApps(id, type) {
  return getSettings(`/settings/screenApps/?id=${id}&type=${type}`);
}

export function getIdleSettings(id, type) {
  return getSettings(`/settings/idle/?id=${id}&type=${type}`);
}

export function getShadowCopySettings(id, type) {
  return getSettings(`/settings/shadow/?id=${id}&type=${type}`);
}

export function getFileSettings(id, type) {
  return getSettings(`/settings/files/?id=${id}&type=${type}`);
}

export function getDriveSettings(id, type) {
  return getSettings(`/settings/drives/?id=${id}&type=${type}`);
}

export function getInetSettings(id, type) {
  return getSettings(`/settings/internet/?id=${id}&type=${type}`);
}

export function getWebcamSettings(id, type) {
  return getSettings(`/settings/webcam/?id=${id}&type=${type}`);
}

export function getNotificationSettings(id, type) {
  return getSettings(`/settings/notifSett/?id=${id}&type=${type}`);
}

export function getGeneralSettings() {
  return axios.get('/settings/general').then(({ data }) => data);
}

export function getSearchType() {
  return axios.get('/settings/searchType').then(({ data }) => data);
}

export function getGenSettings() {
  return axios.get('/settings/generalSettings').then(({ data }) => data);
}

export function getMaxUsers() {
  return axios.get('/settings/maxUsers').then(({ data }) => data);
}

export function getMaxComputers() {
  return axios.get('/settings/maxComputers').then(({ data }) => data);
}

export function getProfiles() {
  return axios.get('/settings/profiles').then(({ data }) => data);
}

export function getCompRemote() {
  return axios.get('/settings/remote').then(({ data }) => data);
}

export function setProfileForPC(profId, compId) {
  return axios.post(`/settings/userProfile/?profId=${profId}&compId=${compId}`).then((response) => {
    if (response.data.success) {
      openToast('Профиль пользователя успешно обновлен', 'success');
    } else {
      openToast(response.data.message, 'error');
    }
  });
}

export function setProfileForDep(profId, depId) {
  return axios.post(`/settings/groupProfile/?profId=${profId}&depId=${depId}`).then((response) => {
    if (response.data.success) {
      openToast('Профиль группы успешно обновлен', 'success');
    } else {
      openToast(response.data.message, 'error');
    }
  });
}

export function addProfile(name, baseId) {
  return axios.post(`/settings/newProfile/?name=${name}&baseId=${baseId}`).then((response) => {
    if (response.data.success) {
      openToast('Профиль успешно добавлен', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function saveProfiles(added, removed) {
  return axios.post('/settings/saveProfiles', { added, removed }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function deleteProfile(id) {
  if (id !== 1) {
    return axios.post(`/settings/deleteProfile/?id=${id}`).then((response) => {
      if (response.data.success) {
        openToast('Профиль успешно удален', 'success');
      } else {
        openToast(response.data.message, 'error');
      }
    });
  }
  openToast('Базовый профиль не может быть удален', 'error');
  return 'error';
}

export function changeSettings(id, newValue) {
  return axios.post(`/settings/change/?id=${id}&newValue=${newValue}`).then((response) => {
    if (response.data.success) {
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function setUserDep(id, groupId) {
  return axios.post(`/settings/setUserDep/?id=${id}&groupId=${groupId}`).then((response) => {
    if (!response.data.success) {
      openToast(response.data.message, 'error');
    }
  });
}

export function setDepParent(id, parentId) {
  return axios.post(`/settings/setDepParent/?id=${id}&parentId=${parentId}`).then((response) => {
    if (!response.data.success) {
      openToast(response.data.message, 'error');
    }
  });
}

export function deleteUser(id) {
  return axios.post(`/settings/deleteUser/?id=${id}`).then((response) => {
    if (response.data.success) {
      openToast('Пользователь успешно удален', 'success');
    } else {
      openToast(response.data.message, 'error');
    }
  });
}

export function deleteGroup(id, depOnly) {
  return axios.post(`/settings/deleteGroup/?id=${id}&depOnly=${depOnly}`).then((response) => {
    if (response.data.success) {
      openToast('Группа успешно удалена', 'success');
    } else {
      openToast(response.data.message, 'error');
    }
  });
}

export function renameUser(id, name) {
  return axios.post(`/settings/renameUser/?id=${id}&name=${name}`).then((response) => {
    if (response.data.success) {
      openToast('Имя пользователя успешно обновлено', 'success');
    } else {
      openToast(response.data.message, 'error');
    }
  });
}

export function renameGroup(id, name) {
  return axios.post(`/settings/renameGroup/?id=${id}&name=${name}`).then((response) => {
    if (response.data.success) {
      openToast('Название группы успешно обновлено', 'success');
    } else {
      openToast(response.data.message, 'error');
    }
  });
}

export function addRemote(compArray) {
  return axios.post('/settings/addRemote', { compArray }).then((response) => {
    if (response.data.success) {
      if (response.data.result > 0) {
        openToast('Пользователи успешно добавлены', 'success');
        return 'success';
      }

      if (response.data.result === 0) {
        openToast('Пользователь уже существует', 'error');
        return 'error';
      }

      if (response.data.result === -1) {
        openToast('Достигнуто максимальное число компьютеров, предусмотренное Вашей лицензией', 'error');
        return 'error';
      }
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addNewUser(displayName, compName) {
  return axios.post('/settings/newUser', { name: displayName, compName }).then((response) => {
    if (response.data.success) {
      if (response.data.result > 0) {
        openToast('Пользователь успешно добавлен', 'success');
        return 'success';
      }

      if (response.data.result <= 0) {
        openToast('Пользователь уже существует', 'error');
        return 'error';
      }

      if (response.data.result == null) {
        openToast('Достигнуто максимальное число компьютеров, предусмотренное Вашей лицензией', 'error');
        return 'error';
      }
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addNewGroup(name, id) {
  return axios.post('/settings/newGroup', { name, profileId: id }).then((response) => {
    if (response.data.success) {
      if (response.data.result > 0) {
        openToast('Группа успешно добавлена', 'success');
        return 'success';
      }

      openToast('Группа уже существует', 'error');
      return 'error';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function changeCheckedApps(checkedApps, uncheckedApps, compId, type) {
  return axios.post('/settings/changeCheckedApps', { checkedApps, uncheckedApps, compId, type }).then((response) => {
    if (response.data.success) {
      openToast('Список программ успешно обновлен', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function saveSettings(security, acsel, settings, profileId, profileName, prodProfile, wtProfile) {
  return axios.post('/settings/saveSettings',
    { security, acsel, settings, profileId, profileName, prodProfile, wtProfile }).then((response) => {
    if (response.data.success) {
      openToast('Настройки успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getProdProfile(settProfile) {
  return axios.post(`/settings/prodProfile/?id=${settProfile}`).then((response) => {
    if (response.data.success) {
      return response.data;
    }
    return response.data.message;
  });
}

export function getProdGroups(profile) {
  return axios.post(`/settings/prodGroups/?id=${profile}`).then((response) => {
    if (response.data.success) {
      return response.data;
    }
    return response.data.message;
  });
}

export function saveProdGroups(appsToAdd, appsToDelete, webToAdd, webToDelete, profile) {
  return axios.post('/settings/saveProdGroups',
    { appsToAdd, appsToDelete, webToAdd, webToDelete, profile }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addProdProfile(name) {
  return axios.post(`/settings/addProdProfile/?name=${name}`).then((response) => {
    if (response.data.success) {
      openToast('Профиль успешно добавлен', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function deleteProdProfile(id) {
  if (id !== 1) {
    return axios.post(`/settings/delProdprofile/?id=${id}`).then((response) => {
      if (response.data.success) {
        openToast('Профиль успешно удален', 'success');
      } else {
        openToast(response.data.message, 'error');
      }
    });
  }
  openToast('Базовый профиль не может быть удален', 'error');
  return 'error';
}

export function setProdProfile(settProfile, prodProfile) {
  return axios.post(
    `/settings/setProdProfile/?settProfile=${settProfile}&prodProfile=${prodProfile}`,
  ).then((response) => {
    if (response.data.success) {
      openToast('Профиль успешно изменен', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getWtProfile(settProfile) {
  return axios.post(`/settings/wtProfile/?id=${settProfile}`).then((response) => {
    if (response.data.success) {
      return response.data;
    }
    return response.data.message;
  });
}

export function addWtProfile(name) {
  return axios.post(`/settings/addWtProfile/?name=${name}`).then((response) => {
    if (response.data.success) {
      openToast('Профиль успешно добавлен', 'success');
      return 'success';
    }

    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function deleteWtProfile(id) {
  if (id !== 1) {
    return axios.post(`/settings/delWtprofile/?id=${id}`).then((response) => {
      if (response.data.success) {
        openToast('Профиль успешно удален', 'success');
      } else {
        openToast(response.data.message, 'error');
      }
    });
  }
  openToast('Базовый профиль не может быть удален', 'error');
  return 'error';
}

export function setWtProfile(settProfile, wtProfile) {
  return axios.post(
    `/settings/setWtProfile/?settProfile=${settProfile}&wtProfile=${wtProfile}`,
  ).then((response) => {
    if (response.data.success) {
      openToast('Профиль успешно изменен', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getWorktime(wtProfile) {
  return axios.post(
    `/settings/worktime/?wtProfile=${wtProfile}`,
  ).then((response) => {
    if (response.data.success) {
      return response.data;
    }
    return response.data.message;
  });
}

export function saveWorktime(toDelete, toAdd) {
  return axios.post('/settings/saveWorktime',
    { toDelete, toAdd }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function changeCalendar(profId, useCalendar) {
  return axios.post('/settings/changeWtCalendar',
    { profId, useCalendar }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addAcselParams(profId, val, sval) {
  return axios.post(`/settings/addAcselParams/?profId=${profId}&val=${val}`,
    { sval }).then((response) => {
    if (response.data.success) {
      openToast('Путь успешно добавлен', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function delAcselParams(id) {
  return axios.post(`/settings/delAcselParams/?id=${id}`).then((response) => {
    if (response.data.success) {
      openToast('Путь успешно удален', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function editAcselParams(id, val, sval) {
  return axios.post(`/settings/editAcselParams/?id=${id}&val=${val}`,
    { sval }).then((response) => {
    if (response.data.success) {
      openToast('Путь успешно отредактирован', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getSerials(compId) {
  return getSettings(`/settings/serials/?compId=${compId}`);
}

export function setSerial(compId, usbArray) {
  return axios.post(`/settings/setSerial/?compId=${compId}`,
    { usbArray }).then((response) => {
    if (response.data.success) {
      if (usbArray.length === 1) openToast('Устройство успешно добавлено', 'success');
      if (usbArray.length > 1) openToast('Устройства успешно добавлены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function setSerialForProfile(profId, usbArray) {
  return axios.post(`/settings/setSerialProfile/?profId=${profId}`,
    { usbArray }).then((response) => {
    if (response.data.success) {
      if (usbArray.length === 1) openToast('Устройство успешно добавлено', 'success');
      if (usbArray.length > 1) openToast('Устройства успешно добавлены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function setSerialForAll(usbArray) {
  return axios.post('/settings/setSerialAll/',
    { usbArray }).then((response) => {
    if (response.data.success) {
      if (usbArray.length === 1) openToast('Устройство успешно добавлено', 'success');
      if (usbArray.length > 1) openToast('Устройства успешно добавлены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function removeSerial(usbArray) {
  return axios.post('/settings/removeSerial/',
    { usbArray }).then((response) => {
    if (response.data.success) {
      if (usbArray.length === 1) openToast('Устройство успешно удалено из списка', 'success');
      if (usbArray.length > 1) openToast('Устройства успешно удалены из списка', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function removeSerialForAll(usbArray) {
  return axios.post('/settings/removeSerialAll/',
    { usbArray }).then((response) => {
    if (response.data.success) {
      if (usbArray.length === 1) openToast('Устройство успешно удалено из списка', 'success');
      if (usbArray.length > 1) openToast('Устройства успешно удалены из списка', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function delSerial(usbArray) {
  return axios.post('/settings/delSerial/',
    { usbArray }).then((response) => {
    if (response.data.success) {
      if (usbArray.length === 1) openToast('Устройство успешно удалено', 'success');
      if (usbArray.length > 1) openToast('Устройства успешно удалены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function editComment(id, comment) {
  return axios.post(`/settings/editComment/?id=${id}`,
    { comment }).then((response) => {
    if (response.data.success) {
      openToast('Комментарий успешно сохранен', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addSerial(usbArray) {
  return axios.post('/settings/addSerial/',
    { usbArray }).then((response) => {
    if (response.data.success) {
      openToast(`Добавлено устройств: ${response.data.result.added}<br>Устройств, уже бывших в списке: 
      ${response.data.result.skipped}`, 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getExceptions(id, type) {
  return axios.post(
    `/settings/exceptions/?id=${id}?type=${type}`,
  ).then((response) => {
    if (response.data.success) {
      return response.data;
    }
    return response.data.message;
  });
}

export function editExceptions(profile, exceptions, stype) {
  return axios.post(`/settings/editExceptions/?profile=${profile}&stype=${stype}`,
    { exceptions }).then((response) => {
    if (response.data.success) {
      if (stype === 2) openToast('Список приложений успешно сохранен', 'success');
      if (stype === 3) openToast('Список сайтов успешно сохранен', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getAcselParams(id, type) {
  return getSettings(`/settings/acselParams/?id=${id}&type=${type}`);
}

export function saveGeneralSettings(changed) {
  return axios.post('/settings/saveGeneral/',
    { changed }).then((response) => {
    if (response.data.success) {
      openToast('Настройки успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}
