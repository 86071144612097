<template>
  <div>
    <div :class="[$style.card]">
      <div
        :class="[$style.settContainer, $style.toggle]"
        @click="check(settings[0].stype)"
      >
        <div :class="[$style.label, $style.idle]">
          {{ settings[0].description }}
        </div>

        <div :class="$style.flex">
          <input
            v-model="settings[0].domain"
            type="text"
            :class="$style.inputText"
            :disabled="!settings[0].checked"
            @change="change(settings[0].stype)"
            @click.stop
          >
        </div>
        <toggle-button
          v-model="settings[0].checked"
          :value="settings[0].checked"
          :sync="true"
          @click.native.prevent
        />
      </div>
      <div
        v-if="settings[0].additional !== undefined"
        :class="[$style.settContainer, $style.additional]"
        @click="check(settings[0].stype, true)"
      >
        <div :class="$style.label">
          Только если есть вложение
        </div>
        <toggle-button
          v-model="settings[0].additional"
          :value="settings[0].additional"
          :sync="true"
          :disabled="!settings[0].checked"
          @click.native.prevent
        />
      </div>
      <div :class="$style.settContainer" @click="check(settings[1].stype)">
        <div :class="$style.label">
          {{ settings[1].description }}
        </div>
        <toggle-button
          v-model="settings[1].checked"
          :value="settings[1].checked"
          :sync="true"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="[$style.card]">
      <div
        v-for="sett in settings.slice(2, 4)"
        :key="sett.stype"
        :class="[$style.line]"
      >
        <div :class="[$style.settContainer]" @click="check(sett.stype)">
          <div :class="[$style.label, $style.idle]">
            {{ sett.description }}
          </div>

          <div :class="$style.flex">
            <div v-if="sett.additional !== undefined" :class="$style.measure">
              при размере файла более
            </div>
            <input
              v-model.number="sett.val"
              type="number"
              :class="$style.input"
              :disabled="!sett.checked"
              @change="change(sett.stype)"
              @click.stop
            >
            <div :class="$style.measure">
              МБ
            </div>
          </div>
          <toggle-button
            v-model="sett.checked"
            :value="sett.checked"
            :sync="true"
            @click.native.prevent
          />
        </div>
        <div
          v-if="sett.additional !== undefined"
          :class="[$style.settContainer, $style.additional]"
          @click="check(sett.stype, true)"
        >
          <div :class="$style.label">
            Только в нерабочее время
          </div>
          <toggle-button
            v-model="sett.additional"
            :value="sett.additional"
            :sync="true"
            :disabled="!sett.checked"
            @click.native.prevent
          />
        </div>
      </div>
    </div>
    <div :class="[$style.card]">
      <div
        v-for="sett in settings.slice(4, 6)"
        :key="sett.stype"
        :class="[$style.line]"
      >
        <div :class="[$style.settContainer]" @click="check(sett.stype)">
          <div :class="[$style.label, $style.idle]">
            {{ sett.description }}
          </div>

          <div :class="$style.flex">
            <div v-if="sett.additional !== undefined" :class="$style.measure">
              при размере файла более
            </div>
            <input
              v-model.number="sett.val"
              type="number"
              :class="$style.input"
              :disabled="!sett.checked"
              @change="change(sett.stype)"
              @click.stop
            >
            <div :class="$style.measure">
              МБ
            </div>
          </div>
          <toggle-button
            v-model="sett.checked"
            :value="sett.checked"
            :sync="true"
            @click.native.prevent
          />
        </div>
        <div
          v-if="sett.additional !== undefined"
          :class="[$style.settContainer, $style.additional]"
          @click="check(sett.stype, true)"
        >
          <div :class="$style.label">
            Только в нерабочее время
          </div>
          <toggle-button
            v-model="sett.additional"
            :value="sett.additional"
            :sync="true"
            :disabled="!sett.checked"
            @click.native.prevent
          />
        </div>
      </div>
    </div>
    <div :class="[$style.card]">
      <label
        :class="$style.settContainer"
        @click="check(settings[7].stype)"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ settings[7].description }}
        </div>
        <toggle-button
          v-model="settings[7].checked"
          :value="settings[7].checked"
          :sync="true"
          @click.native.prevent
        />
      </label>
      <div
        v-for="sett in settings.slice(8, 10)"
        :key="sett.stype"
        :class="[$style.settContainer, $style.toggle]"
        @click="check(sett.stype)"
      >
        <div :class="[$style.label, $style.idle]">
          {{ sett.description }}
        </div>

        <div :class="$style.flex">
          <input
            v-model.number="sett.val"
            type="number"
            :class="$style.input"
            :disabled="!sett.checked"
            @change="change(sett.stype)"
            @click.stop
          >
          <div :class="$style.measure">
            {{ sett.measure }}
          </div>
        </div>
        <toggle-button
          v-model="sett.checked"
          :value="sett.checked"
          :sync="true"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="[$style.card]">
      <label
        :class="$style.settContainer"
        @click="check(settings[6].stype)"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ settings[6].description }}
        </div>
        <toggle-button
          v-model="settings[6].checked"
          :value="settings[6].checked"
          :sync="true"
          @click.native.prevent
        />
      </label>
      <label
        v-for="sett in settings.slice(-2)"
        :key="sett.stype"
        :class="$style.settContainer"
        @click="check(sett.stype)"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ sett.description }}
        </div>
        <toggle-button
          v-model="sett.checked"
          :value="sett.checked"
          :sync="true"
          @click.native.prevent
        />
      </label>
    </div>
    <div :class="$style.card">
      <div
        v-if="fieldType('Device_alarm')"
        :class="[$style.settContainer, $style.toggle]"
        @click="checkSett('Device_alarm')"
      >
        <div :class="$style.label">
          Подключение/отключение съемного носителя информации
        </div>
        <toggle-button
          v-model="settingsFromXml.Device_alarm"
          :value="settingsFromXml.Device_alarm"
          :sync="true"
          @click.native.prevent
        />
      </div>
      <div
        v-if="fieldType('UnInst_alarm')"
        :class="[$style.settContainer, $style.toggle]"
        @click="checkSett('UnInst_alarm')"
      >
        <div :class="$style.label">
          Установка/удаление программ на ПК
        </div>
        <toggle-button
          v-model="settingsFromXml.UnInst_alarm"
          :value="settingsFromXml.UnInst_alarm"
          :sync="true"
          @click.native.prevent
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import {
  getAcselParams,
  getNotificationSettings,
} from '@/api/methods/settings';

Vue.component('ToggleButton', ToggleButton);

export default {
  data: () => ({
    settings: [
      {
        description: 'При отправке почты на все адреса, кроме домена',
        stype: 10,
        checked: false,
        domain: ' ',
        additional: false,
        sval: '',
        val: 0,
      },
      {
        description: 'При отправке письма через браузер',
        stype: 12,
        checked: false,
        sval: '',
        val: 0,
      },
      {
        description: 'При копировании файла на USB-накопитель',
        stype: 11,
        checked: false,
        val: 0,
        additional: false,
        sval: '',
      },
      {
        description:
          'Копирование на накопитель файлов за день общим размером более',
        stype: 21,
        checked: false,
        val: 300,
        sval: '',
      },
      {
        description: 'При выгрузке файла через браузер',
        stype: 13,
        checked: false,
        val: 0,
        additional: false,
        sval: '',
      },
      {
        description:
          'Выгрузка файлов за день через браузер общим размером более',
        stype: 18,
        checked: false,
        val: 300,
        sval: '',
      },
      {
        description: 'Включение компьютера/вход пользователя в нерабочее время',
        stype: 14,
        checked: false,
        sval: '',
        val: 0,
      },
      {
        description: 'Печать документов на принтере в нерабочее время',
        stype: 15,
        checked: false,
        sval: '',
        val: 0,
      },
      {
        description: 'Печать за день на принтере более',
        stype: 19,
        checked: false,
        measure: 'документов',
        val: 0,
        sval: '',
      },
      {
        description: 'Печать за день на принтере более',
        stype: 20,
        checked: false,
        measure: 'страниц',
        val: 0,
        sval: '',
      },
      {
        description:
          'Переписка (почта, мессенджеры, социальные сети) в нерабочее время',
        stype: 16,
        checked: false,
        sval: '',
        val: 0,
      },
      {
        description: 'Изменение конфигурации оборудования на ПК',
        stype: 17,
        checked: false,
        sval: '',
        val: 0,
      },
    ],
    result: {},
    settingsFromXml: {},
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    checkSett(key) {
      this.$set(this.settingsFromXml, key, !this.settingsFromXml[key]);
      this.$store.commit('settings/addNotificationSettings', {
        key,
        value: this.settingsFromXml[key],
      });
    },
    fieldType(field) {
      return typeof this.settingsFromXml[field] !== 'undefined';
    },
    check(stype, additional) {
      const indexOfObject = this.settings.findIndex(
        (object) => object.stype === stype,
      );
      if (additional) {
        if (this.settings[indexOfObject].checked) {
          this.$set(
            this.settings[indexOfObject],
            'additional',
            !this.settings[indexOfObject].additional,
          );
        }

        if (
          this.settings[indexOfObject].stype === 11
          || this.settings[indexOfObject].stype === 13
        ) {
          if (this.settings[indexOfObject].additional) {
            this.settings[indexOfObject].sval = 'NWT';
          } else this.settings[indexOfObject].sval = '';
        }
        if (this.settings[indexOfObject].stype === 10) {
          if (this.settings[indexOfObject].additional) {
            this.settings[indexOfObject].val = 1;
          } else this.settings[indexOfObject].val = 0;
        }
      } else {
        this.$set(
          this.settings[indexOfObject],
          'checked',
          !this.settings[indexOfObject].checked,
        );
      }
      this.$store.commit('settings/addAcsel', this.settings[indexOfObject]);
    },
    change(stype) {
      const indexOfObject = this.settings.findIndex(
        (object) => object.stype === stype,
      );
      if (this.settings[indexOfObject].stype === 10) {
        this.settings[indexOfObject].sval = this.settings[indexOfObject].domain;
      }
      this.$store.commit('settings/addAcsel', this.settings[indexOfObject]);
    },
    async getSettings() {
      this.result = {};
      let type = 0;
      if (this.selectedComputer.id) {
        this.promise = await getAcselParams(
          this.selectedComputer.id,
          this.selectedComputer.type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
            const settings = this.settings.slice();
            settings.forEach((item) => {
              const found = this.result.find(
                (sett) => sett.stype === item.stype,
              );
              if (found) {
                item.checked = true;
                item.val = found.val;
                item.sval = found.sval;
                if (item.stype === 10) {
                  item.domain = found.sval;
                  if (found.val === 1) item.additional = true;
                } else if (found.sval === 'NWT') item.additional = true;
              }
            });
            this.settings = settings;
          }
        });
        if (this.selectedComputer.type === 'folder') type = 2;
        this.promise = await getNotificationSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.settingsFromXml = data.result;
          }
        });
        const sett = this.$store.getters['settings/notificationSettings'];
        if (sett) {
          Object.entries(sett).forEach(([key, value]) => {
            this.settingsFromXml[key] = value;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 60px;
}

.inputText {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 160px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}
.line {
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 230px;
  cursor: pointer;
}

.title {
  margin: 20px 20px 10px 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.measure {
  font-size: 14px;
  margin-right: 5px;
}

.idle {
  width: 300px;
  margin: 0px 10px;
  line-height: 20px;
}
.flex {
  display: flex;
  align-items: baseline;
}

.additional {
  padding: 10px 0px 10px 50px;
  margin: 10px;
}
</style>
